
declare let gtag: Function // eslint-disable-line
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Button from '@/components/elements/Button.vue'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

interface Language {
  name: string
  label: string
  disabled: boolean
}

export default defineComponent({
  name: 'Home',

  components: {
    Button,
  },

  setup() {
    gtag('event', 'lang')
    const router = useRouter()
    const store = useStore()

    const languages: Language[] = [
      {
        name: 'ja',
        label: '日本語',
        disabled: false,
      },
      {
        name: 'en',
        label: 'ENGLISH',
        disabled: false,
      },
      {
        name: 'zh',
        label: '簡体中文',
        disabled: false,
      },
      {
        name: 'ko',
        label: '한국어',
        disabled: false,
      },
    ]

    const moveMainPage = (language: string) => {
      const savedLanguage = Cookies.get(app_cookie.lang)

      // 言語を保存していない 保存している言語と遷移が違う場合は初期化済みをリセット
      if (!savedLanguage || savedLanguage !== language)
        store.commit('unInitializedApp')

      router.push(`/${language}`)
    }

    return {
      languages,
      moveMainPage,
    }
  },
})
